import domReady from '@roots/sage/client/dom-ready';
import Masonry from 'masonry-layout';

/**
 * Application entrypoint
 */
domReady(async () => {
  let button = document.getElementById('disclosure');
  let bars3 = document.getElementById('bars3');
  let xmark = document.getElementById('xmark');
  let mobileMenu = document.getElementById('mobile-menu');
  button.addEventListener('click', buttonDisclosure);
  function buttonDisclosure() {
    if (button.ariaExpanded === 'true') {
      button.ariaExpanded = 'false';
      bars3.classList.remove('hidden');
      xmark.classList.add('hidden');
      mobileMenu.classList.remove('animate-open-menu');
      mobileMenu.classList.add('animate-close-menu');
      setTimeout(() => {
        mobileMenu.classList.add('hidden');
      }, 90);
    } else {
      button.ariaExpanded = 'true';
      bars3.classList.add('hidden');
      xmark.classList.remove('hidden');
      mobileMenu.classList.remove('hidden', 'animate-close-menu');
      mobileMenu.classList.add('animate-open-menu');
    }
  }
  /* eslint-disable */
  var masonry_element = document.querySelector('.masonry');
  if (typeof masonry_element != 'undefined' && masonry_element != null) {
    var msnry = new Masonry('.masonry', {
      itemSelector: '.post',
      horizontalOrder: true,
      percentPosition: true,
      resize: true,
    });
  }
  /* eslint-disable */
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
